// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_ts";
export var lbContainerInner = "v_tt";
export var movingForwards = "v_tv";
export var movingForwardsOther = "v_tw";
export var movingBackwards = "v_tx";
export var movingBackwardsOther = "v_ty";
export var lbImage = "v_tz";
export var lbOtherImage = "v_tB";
export var prevButton = "v_tC";
export var nextButton = "v_tD";
export var closing = "v_tF";
export var appear = "v_tG";